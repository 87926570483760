<template>
  <div class="app">
    <div class="way">
      <p>请选择上传方式</p>
      <p>仅支持cls、clsx文件，大小不超过150KB</p>
    </div>

    <div class="oder_btn">
      <!-- <p class="">方式1</p>
      <div class="oder_btn_way1" @click="openCamera">
        <img src="@/assets/images/uploadOeder/容器.png" alt=""> 
        <p>扫码上传</p>
      </div> -->


      <!-- <p style="padding:0 40px ;">
        用电脑访问https://iot.yuna6.com/distributionLogin链接后，点击扫码登陆</p>
      <p class="way2_test">方式2</p> -->
      <van-uploader
        v-model="fileList"
        :deletable="false"
        :before-read="beforeRead"
        :preview-image="false"
        :after-read="afterRead"
        accept=""
      >
        <div class="oder_btn_way2"><img
            src="@/assets/images/uploadOeder/路径.png"
            alt=""
          > <p>从手机文件上传</p> </div>
      </van-uploader>
      <p>从手机文件管理中上传</p>
    </div>

    <div class="opload_log" @click="UploadLog"
      >
       <img
          src="@/assets/images/uploadOeder/upload.png"
          alt=""
        >
          <p>查看上传记录</p>
    </div>
    <div class="container_text" @click="down"
      >
       <img
          src="@/assets/images/uploadOeder/下载.png"
          alt=""
        >
        <div class="download_text">
          <p>下载文件模板</p>
          <p>请参考文件模板按标注填写</p>
        </div>
    </div>

  </div>
</template>

<script>
import { importFile } from '@/untils/distubritionIndex.js'
import axios from 'axios';
import { Upload } from 'element-ui';
export default {
  data () {
    return {
      fileList: [],
      info: {},
      ownerid: ''
    }
  },
  mounted () {
    this.info = JSON.parse(sessionStorage.getItem('UserInfo'))
    // console.log(this.info);
    if (this.$route.query.id) {
      this.ownerid = this.$route.query.id
      //   console.log(this.ownerid);
    }

  },
  methods: {
    UploadLog(){
      this.$router.push({path:'/uploadRecord'})
    },
    back () {
      this.$router.go(-1)
    },
    afterRead (file) {
      //   if (this.$route.query.id) {
      //     this.info.id = this.$route.query.id
      //   }
      console.log(file.file);
      const formData = new FormData()
      formData.append('file', file.file);
      formData.append('user_id', this.info.id);
      formData.append('owner_id', this.ownerid == '' ? this.info.id : this.ownerid);
      //   console.log(this.info.id);
      //   console.log(this.ownerid);
      axios({
        method: 'post',
        url: "https://connect.yuna6.com/api/v1.Distribution/import",
        data: formData
      }).then(res => {
        console.log(res);
        if (res.data.code == 200) {
          this.$toast(res.data.msg)
        }
      })

    },
    // 打开扫一扫
    openCamera () {
      let u = navigator.userAgent
      let isAndroid = u.indexOf('Android') > -1 || u.indexOf('Linux') > -1; //g
      let isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
      if (isAndroid) {
        window.android.OpenScan(); // 调用原生app的函数--webClose是安卓提供的方法
      }
      if (isIOS) {
        window.webkit.messageHandlers.OpenScan.postMessage('');//--webClose是ios提供的方法
      }
      // window.webkit.messageHandlers.OpenScan.postMessage('');

    },

    down () {
      window.location.href = 'https://connect.ydbimg.com/download_file/demo.xlsx'
    },
    beforeRead (file) {
      let type = file.name
      let arr = type.split('.')
      // console.log(arr);
      if (arr[1] == 'xls' || arr[1] == 'xlsx') {
        return true
      } else {
        return false
      }
    }
  }
}
</script>

<style>
body {
  background-color: #fff;

}
.app{
  /* box-sizing: border-box; */
  border: 1px solid #fff;
}
.way {
  text-align: center;
}

.way p:nth-child(1) {
  font-size: 16px;
  font-weight: 550;
  margin-top: 30px;
}

.way p:nth-child(2) {
  font-size: 12px;
  margin-top: 10px;
  color: #999;
}

.oder_btn {
  text-align: center;
}

.oder_btn p:nth-child(1) {
  margin-top: 20px;
  font-size: 12px;
  line-height: 25px;
  font-weight: 500;
}

.oder_btn p:nth-child(3) {
  /* margin-top: 10px; */
  line-height: 25px;
  color: #666;
  font-size: 12px;
}

.oder_btn p:nth-child(4) {
  margin-top: 45px;
  font-size: 12px;
  line-height: 25px;
  font-weight: 500;
}

.oder_btn p:nth-child(6) {
  line-height: 25px;
  color: #666;
}
.opload_log{
  border-radius: 60px;
  width: 300px;
  height: 56px;
  margin: 0 auto;
  margin-top: 129px;
  display: flex;
  justify-content: center;
  align-items: center;
  /* background-color: #ecf9ff; */
  border: 1px solid #CCCCCC;
  line-height: 56px;
  position: relative;
}

.opload_log img {
  width: 26px;
  height: 25px;
  position: absolute;
  top: 50%;
  margin-top: -16px;
  left: 57px;
}
.opload_log p{
  font-size: 16px;
  color: #3D3D3D;
  font-weight: 550;
  text-align: center;
  margin-left: 20px;
}
.container_text{
  border-radius: 60px;
  width: 300px;
  height: 56px;
  margin: 0 auto;
  margin-top: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  /* background-color: #ecf9ff; */
  border: 1px solid #CCCCCC;
}

.container_text img {
  width: 26px;
  height: 25px;
}
.container_text .download_text {
  margin-left: 7px;
  text-align: center;
}
.container_text .download_text p:nth-child(1) {
  font-size: 16px;
  color: #3D3D3D;
  font-weight: 550;

}
.container_text .download_text p:nth-child(2) {
  font-size: 10px;
  color: #999999;
  margin-top: 3px;
  padding: 0 5px;
}
.oder_btn_way1 {
  border-radius: 60px;
  width: 300px;
  height: 56px;
  line-height: 56px;
  margin: 0 auto;
  background-color: #ecf9ff;
  position: relative;
}
.oder_btn_way1 img{
  position: absolute;
  top: 50%;
  margin-top: -16px;
  left: 57px;
}
.oder_btn_way1 p{
  font-size: 16px;
  color: #3D3D3D;
  font-weight: 550;
}
.oder_btn_way2{
  border-radius: 60px;
  width: 300px;
  height: 56px;
  line-height: 56px;
  margin: 0 auto;
  background-color: #ecf9ff;
  position: relative;
}
.oder_btn_way2 img{
  position: absolute;
  top: 50%;
  margin-top: -16px;
  left: 57px;
}
.oder_btn_way2 p{
  font-size: 16px;
  color: #3D3D3D;
  font-weight: 550;
}
</style>